import React from 'react';
import styled from 'styled-components';

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function Team(props) {
    const { title, members } = props;
    return (
        <TeamRow>
            <h2>{title}</h2>
            <TeamWrapper>
                {members.map(member => (
                    <Member key={member._key}>
                        <Pic background={imageUrlFor(member.image).url()} />
                        <h3>{member.name}</h3>
                        <p>{member.jobTitle}</p>
                    </Member>
                ))}
            </TeamWrapper>
        </TeamRow>
    );
}

export default Team;

const TeamRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  h2 {
    margin: 16px 0 32px 0;
  }
`;

const TeamWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  grid-gap: 24px;
  margin-bottom: 24px;
  width: 90%;

  @media (min-width: 1024px) {
    grid-gap: 32px;
    margin-bottom: 32px;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  @media (min-width: 1240px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Member = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  h3 {
    font-size: 24px;
    margin: 0;

    @media (min-width: 600px) {
      font-size: 30px;
    }
  
    @media (min-width: 800px) {
      font-size: 28px;
    }
  
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 14px;
    border-radius: 4px;
    padding: 2px 8px 6px;
    font-weight: 500;
    text-align: center;
  }
`;

const Pic = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 100%;
  min-height: 110px;
  min-width: 110px;
  height: 110px;
  overflow: hidden;
  transition: all 0.2s;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  color: white;
`;