import React, { useEffect } from 'react';
import styled from 'styled-components';

function HTMLBlock(props) {
    const innerHtmlProp = props?.html?.code;

    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(innerHtmlProp, 'text/html');

        // Find all script tags in the parsed HTML
        const scriptElements = doc.querySelectorAll('script');

        // Execute scripts with src attributes
        scriptElements.forEach(scriptElement => {
            const src = scriptElement.getAttribute('src');
            if (src) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.src = src;
                document.body.appendChild(script);
            } else {
                // Extract and execute inline scripts
                const inlineScriptContent = scriptElement.textContent;
                try {
                    const scriptFunction = new Function(inlineScriptContent);
                    scriptFunction();
                } catch (error) {
                    console.error('Error executing script:', error);
                }
            }
        });
    }, [innerHtmlProp]);

    return <HtmlBlock dangerouslySetInnerHTML={{ __html: innerHtmlProp }} />;
}

export default HTMLBlock;

const HtmlBlock = styled.div`
    display: block;
    width: 100%;
`;
