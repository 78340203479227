import React, { useEffect } from 'react';
import styled from 'styled-components';

export const HealthAdvice = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
      return (
        <Section>
            <iframe src="https://api-bridge.azurewebsites.net/conditions/" title="Health advice" frameBorder="0" width="100%" height="100%" style={{height: 'calc(100vh - 40px)'}} />
        </Section>
      );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: -76px;
`;