import React from 'react';
import styled from 'styled-components';
import { ImBullhorn } from "react-icons/im";
import { MdAddTask, MdBugReport, MdDeliveryDining, MdAlarmAdd } from "react-icons/md";
import { RiRepeatOneFill } from "react-icons/ri";
import { BiTimeFive, BiLocationPlus } from "react-icons/bi";

function IconRow(props) {
  const {icons} = props;

  const returnIcon = (icon) => {
    switch (icon) {
      case "news":
        return <ImBullhorn />;
      case "hours":
        return <MdAlarmAdd />;
      case "tick":
        return <MdAddTask />;
      case "virus":
        return <MdBugReport />;
      case "delivery":
        return <MdDeliveryDining />;
      case "repeat":
        return <RiRepeatOneFill />;
      case "clock":
        return <BiTimeFive />;
      case "location":
        return <BiLocationPlus />;
      default:
        return <ImBullhorn />;
    }
  };

  return icons && icons.length ? (
    <RowElement cols={icons.length}>
        {icons.map(icon => (
            <IconWrapper key={icon._key} className={'IconWrapper'}>
                <Icon className={'Icon'}>{returnIcon(icon.icon)}</Icon>
                <IconHeader>{icon.heading}</IconHeader>
                <IconContent>{icon.content}</IconContent>
            </IconWrapper>
        ))}
    </RowElement>
  ) : null;
}

export default IconRow;

const RowElement = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  grid-gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(${props => props.cols === '1' ? '1' : '2'}, minmax(0px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-gap: 32px;
    margin-bottom: 32px;
    grid-template-columns: repeat(${props => props.cols}, minmax(0px, 1fr));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  padding: 16px 0;

  @media (min-width: 1024px) {
    padding: 32px 0;
  }
`;

const Icon = styled.div`
  padding: 0;
  text-align: center;
  
  svg {
    font-size: 32px;
    color: #bbbbbb;
  }

  @media (min-width: 1024px) {
    padding: 16px 0;

    svg {
      font-size: 42px;
    }
  }
`;

const IconHeader = styled.h4`
  font-size: 22px !important;
  font-weight: bold;
  padding: 0px 0 16px;
  margin: 0 !important;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const IconContent = styled.p`
  font-size: 16px !important;
  margin: 0 !important;
  text-align: center;
`;