import React from 'react';
import styled from 'styled-components';

function Row({children, cols}) {
  return (
    <RowElement cols={cols}>
        {children}
    </RowElement>
  );
}

export default Row;

const RowElement = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  grid-gap: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(${props => props.cols === '1' ? '1' : '2'}, minmax(0px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-gap: 32px;
    margin-bottom: 32px;
    grid-template-columns: repeat(${props => props.cols}, minmax(0px, 1fr));
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;