import React from 'react';
import styled from 'styled-components';

function Video(props) {
    return (
        <VideoBlock>
            <VideoIframe
                src={props.url}
                frameBorder="0"
            />
        </VideoBlock>
    );
}

export default Video;

const VideoBlock = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;