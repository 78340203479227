import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { Link } from "gatsby";
import TextTruncate from 'react-text-truncate';

import { getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import Hero from './hero';

function Carousel(props) {
  const {type, items} = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settingsFade = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: true,
    arrows: false
  };

  const sliderSettings = type === 'fade' ? settingsFade : settings;

  const returnItem = (block) => {
    let bg = '';

    if (block && block.mainImage) {
      bg = imageUrlFor(block.mainImage).url();
    }
    
    switch (block._type) {
      case "hero":
        return <Hero {...block} />;
      case "post":
        return (
          <Link to={getBlogUrl(block.slug.current)}>
            <SliderItem style={{backgroundImage: `url(${bg})`}}>
              <Tag><span>{block.categories[0].title}</span></Tag>
              <TextTruncate
                line={2}
                element="h3"
                truncateText="…"
                text={block.title}
              />
            </SliderItem>
          </Link>
        );
      default:
        return null;
    }
  };

  return items && items.length ? (
    <SliderWrapper>
        <Slider {...sliderSettings}>
          {items.map((block,idx) => (
            <SliderDiv key={`${block?.slug?.current}-${block._key}-new-${idx}`}>
              {returnItem(block)}
            </SliderDiv>
          ))}
        </Slider>
    </SliderWrapper>
  ) : null;
}

export default Carousel;

const SliderWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 24px;
`;

const SliderDiv = styled.div`
  position: relative;
  margin: 0 0 8px 0;
  padding: 0;
`;

const Tag = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 4px;
  margin: -4px;
  border-radius: 7px;
  background: white;

  @media (min-width: 600px) {
    margin: -30px 0 0 -20px;
  }

  span {
    display: flex;
    align-content: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: bolder;
    padding: 2px 7px 5px;
    border-radius: 5px;
    background: #7ab528;

    @media (min-width: 600px) {
      font-size: 14px;
      padding: 4px 8px 8px;
    }
  }
`;

const SliderItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 160px;
  padding: 16px 16px 32px;
  background: #39ffd4;
  border-radius: 10px;
  margin: 0 8px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  transition: all 0.2s ease 0s;

  @media (min-width: 1024px) {
    min-height: 320px;
    padding: 24px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient( 0deg,#000000ad 0%,transparent 100% );
  }

  &:hover {
    box-shadow: 0px 10px 32px -10px rgba(0,0,0,0.1);
  }

  h3 {
    font-size: 22px;
    margin: 0;
    color: white;
    font-weight: 900;
    line-height: 1.2;
    color: white;
    z-index: 1;

    @media (min-width: 1024px) {
      font-size: 28px;
    }
  }

  ${Tag} {
    margin: 0;
    background: none;
    z-index: 1;
  }
`;