import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BiChevronDown } from "react-icons/bi";

import PortableText from "./portableText";

function Accordion(props) {
    const [accordionOpen, setAccordionOpen] = useState('');
    const { items, title } = props;
    
    return items && items.length > 0 ? (
        <>
            {title && (<h3>Frequently Asked Questions</h3>)}
            <AccordionBlock>
            {items.map((faq) => (
                <AccordionItem key={faq._key}>
                    <AccordionToggle onClick={() => setAccordionOpen(accordionOpen === `${faq._type}-${faq._key}` ? '' : `${faq._type}-${faq._key}`)}>
                      <AccordionButton open={accordionOpen === `${faq._type}-${faq._key}`}>{faq.heading} <BiChevronDown /></AccordionButton>
                    </AccordionToggle>
                    <AccordionPanel open={accordionOpen === `${faq._type}-${faq._key}`}>
                        <PortableText blocks={faq.content} />
                    </AccordionPanel>
                </AccordionItem>
            ))}
            </AccordionBlock>
        </>
    ) : null;
}

export default Accordion;

const AccordionBlock = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background: #f0f4f5;
`;

const AccordionItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;

  h3 {
    margin: 0;
  }
`;

const AccordionToggle = styled.h3`
  display: flex;
  width: 100%;
  margin: 0px;
`;

const AccordionButton = styled.button`
  display: flex;
  width: 100%;
  padding: 16px;
  background: white;
  border: none;
  font-size: 18px;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;
  text-align: left;

  svg {
    font-size: 20px;
  }

  ${({open}) => open && css`
    border-radius: 6px 6px 0 0;

    svg {
      transform: rotate(-180deg);
    }
  `};
`;

const AccordionPanel = styled.div`
  padding: 0 16px;
  background: white;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #f0f4f5;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s linear;

  p {
    opacity: 0.7;
  }

  ${({open}) => open && css`max-height: 10000px`};
`;