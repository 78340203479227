import React from 'react';

import Row from './Row';
import Hero from './hero';

function HeroColumns(props) {
  const { columns } = props;
  return columns && columns.length ? (
    <Row cols={columns.length}>
        {columns.map((col, idx) => (
            <Hero {...col} key={`hero-columns-${idx}`} />
        ))}
    </Row>
  ) : null;
}

export default HeroColumns;
