import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby";
import PortableText from "./portableText";
import { imageUrlFor } from "../lib/image-url";
import Space from './Space';

function SideBarCategoryList(props) {
    const { items, title, subtitle } = props;
    return items && items.length > 0 ? (
        <CtaBlock className={'CtaBlock'} background={'white'}>
            <CtaContent className={'CtaContent'}>
                {title && (<Title>{title}</Title>)}
                {subtitle && (<PortableText blocks={subtitle} />)}
                <Space />
                <Categories>
                    {items.map(item => (
                        <Category key={item._key} to={`/${item.route.slug.current}`} background={imageUrlFor(item.image).url()}>
                            <h2>{item.route.page.title}</h2>
                        </Category>
                    ))}
                </Categories>
            </CtaContent>
        </CtaBlock>
    ) : null;
}

export default SideBarCategoryList;

const CtaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 0;
  background: ${props => props.background};
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease 0s;

  &:hover {
    box-shadow: 0px 10px 32px -10px ${props => props.background === 'white' ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0)'};
  }
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 24px;
  text-align: center;

  @media (min-width: 1024px) {
    max-width: 60%;
    padding: 32px;
  }

  button {
    margin-top: 16px;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
`;

const Categories = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  grid-gap: 16px;
  margin-bottom: 16px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Category = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 170px;
  min-height: 170px;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  color: white;

  @media (min-width: 600px) {
    height: 100%;
    min-height: 170px;
  }

  h2 {
    z-index: 2;
    padding: 0 24px;
    font-size: 18px !important;

    @media (min-width: 600px) {
      font-size: 26px !important;
    }
  
    @media (min-width: 800px) {
      font-size: 22px !important;
    }
  
    @media (min-width: 1024px) {
      font-size: 18px !important;
    }
  
    @media (min-width: 1240px) {
      font-size: 22px !important;
    }
  }

  &:hover {
    color: white;

    &:after {
      opacity: 0.5;
    }
  }

  &:active {
    transform: scale(.98);
    color: white;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: black;
    opacity: 0.3;
    z-index: 0;
  }

  .corner-ribbon{
    width: 120px;
    background: #7ab627;
    position: absolute;
    top: 5px;
    right: -40px;
    text-align: center;
    font-size: 11px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 1px;
    color: white;
    transform: rotate(45deg);
    z-index: 4;
  }
  
  .corner-ribbon.shadow{
    box-shadow: 0 0 3px rgba(0,0,0,.3);
  }
`;

const Title = styled.h2`
  width: 100%;
  text-align: left;
  margin: 0 0 -8px !important;
`;