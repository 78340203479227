import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from './Button';

function Contact() {
  const [disableSubmit,setDisableSubmit] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <form name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true">
      <input type="hidden" name="form-name" value="contact" />
      <FormWrapper>
        <FormItem>
          <input type="text" placeholder="Name:" name="name" />
        </FormItem>

        <FormItem>
          <input type="tel" placeholder="Contact number:" name="number" />
        </FormItem>

        <FormItem>
          <input type="email" placeholder="Email address:" name="email" />
        </FormItem>

        <FormItem>
          <select name="reason">
            <option>Please select reason for your message</option>
            <option>General enquiry</option>
            <option>Report a complaint</option>
            <option>A recent visit</option>
            <option>Repeat prescriptions</option>
          </select>
        </FormItem>

        <FormItem>
          <textarea placeholder="Message:" name="message"></textarea>
        </FormItem>

        <FormItem style={{alignItems: 'center', justifyContent: 'center', height: '130px'}}>
          <ReCAPTCHA
            sitekey={process.env.SITE_RECAPTCHA_KEY}
            onChange={useCallback(() => setDisableSubmit(false))}
          />
        </FormItem>

      </FormWrapper>
      <FormButtons>
        <Button text={'Send message'} type={'submit'} disabled={disableSubmit} />
      </FormButtons>
    </form>
  );
}

export default Contact;

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const FormItem = styled.div`
  display: flex;
  min-width: 100%;
  width: 100%;

  @media (min-width: 800px) {
    min-width: 45%;
    width: 50%;
  }

  input, textarea, select {
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
    font-weight: 500;
    line-height: 1.3;
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 18px;
    padding: 4px 16px 8px 16px;
    background: #f0f4f5;
    border-radius: 10px;
    height: 60px;
    color: #424242;
    margin: 8px;

    &:focus-visible {
      outline: #8dbe23 auto 1px;
    }
  }

  textarea {
    height: 120px;
  }
`;

const FormButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin: 24px 0px 0;

  button {
    margin: 0;

    &:first-of-type {
      margin: 24px 0 16px 0;
    }

    @media (min-width: 800px) {
      &:first-of-type {
        margin: 0 16px 0 auto;
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    margin: 24px 8px 0;
  }
`;
